import flagNames from '../featureFlags';
import appNav from '../defaultAppNav';
import autoHopNavMap from '../autoHopNavMap';
import {
  getSuppressAdmissionTranscript,
  getSuppressAdmissionSecondary,
  getIsMilitary,
  hasSSN,
  getIrn,
} from './commonHelper';
import { getProgramFinancialOptions } from './financialHelper';
import { CA_PROG_ELIGIBLE_SEXUAL_ORIENTATION } from '../../modules/admissions/review/acknowledgements/constants';
import { getFeatureFlag } from './featureFlagHelper';

export const transcriptResetPages = ['work', 'financial', 'financialMilitary', 'financialGrants',
  'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialPersonal',
  'financialLoans', 'financialSummary', 'manageFundsCreditBalance', 'review'];

export const secondaryEdResetPages = ['higher', 'expulsionInfo', 'credits', 'transcript', 'work', 'financial', 'financialMilitary', 'financialGrants',
  'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialPersonal',
  'financialLoans', 'financialSummary', 'manageFundsCreditBalance', 'review'];

export const higherEdResetPages = ['expulsionInfo', 'credits', 'transcript', 'work', 'financial', 'financialMilitary', 'financialGrants',
  'financialEmployerBenefits', 'financialDiscounts', 'financialScholarships', 'financialTribalBenefits', 'financialPersonal',
  'financialLoans', 'financialSummary', 'manageFundsCreditBalance', 'review'];

export const MAIN_STEP_INDEXES = {
  PERSONAL: 0,
  PROGRAM: 1,
  EDUCATION: 2,
  WORK: 3,
  FINANCIAL_PLAN: 4,
  MANAGE_FUNDS: 5,
  REVIEW: 6,
};

export const SECONDARY_STEP_INDEXES = {
  // Personal Pages
  CONTACT_INFO: 0,
  TELL_US_MORE: 1,
  MILITARY: 2,

  // Program Pages
  PROGRAM_SELECTION: 0,
  LICENSE_INFO: 1,

  // Education Pages
  SECONDARY: 0,
  HIGHER: 1,
  EXPULSION: 2,
  CREDITS: 3,
  TRANSCRIPT: 4,

  // Work Pages
  WORK: 0,

  // Financial Pages
  FINANCIAL_INTRO: 0,
  FINANCIAL_MILITARY: 1,
  FINANCIAL_GRANTS: 2,
  FINANCIAL_EMPLOYER: 3,
  FINANCIAL_DISCOUNTS: 4,
  FINANCIAL_SCHOLARSHIPS: 5,
  FINANCIAL_TRIBAL: 6,
  FINANCIAL_FUNDING_SOURCES: 7,
  FINANCIAL_PERSONAL: 8,
  FINANCIAL_LOAN: 9,
  FINANCIAL_SUMMARY: 10,
  FINANCIAL_SUMMARY_DOWNLOAD: 11,
  // For merge test
  FINANCIAL_CONTRIBUTIONS: 8,

  // Review and Sign Pages
  REVIEW: 0,
  ACKNOWLEDGE: 1,
  SIGN_AND_SUBMIT: 2,
};

export const copyNavigationObject = navigation => {
  const copyOfNav = {
    ...navigation,
    pages: [],
  };
  navigation.pages.forEach(page => {
    const newPage = {
      ...page,
      secondarySteps: [],
    };
    page.secondarySteps.forEach(secondaryStep => {
      newPage.secondarySteps.push({ ...secondaryStep });
    });

    copyOfNav.pages.push(newPage);
  });
  return copyOfNav;
};

const copyNavPagesObject = navPages => {
  const copyOfNavPages = [];
  navPages.forEach(page => {
    const newPage = {
      ...page,
      secondarySteps: [],
    };
    page.secondarySteps.forEach(secondaryStep => {
      newPage.secondarySteps.push({ ...secondaryStep });
    });
    copyOfNavPages.push(newPage);
  });
  return copyOfNavPages;
};

const setPagesForSCLPTest = navUpdate => {
  const navUpdateForSCLP = navUpdate;
  // Financial Plan Estimate Download
  if (navUpdate
    .pages[MAIN_STEP_INDEXES.FINANCIAL_PLAN]
    .secondarySteps
    .findIndex(t => t.name === 'financialEstimate')
    === SECONDARY_STEP_INDEXES.FINANCIAL_SUMMARY_DOWNLOAD) {
    navUpdateForSCLP.pages[
      MAIN_STEP_INDEXES.FINANCIAL_PLAN
    ].secondarySteps[
      SECONDARY_STEP_INDEXES.FINANCIAL_SUMMARY_DOWNLOAD
    ].enabled = getFeatureFlag(flagNames.SCLP_TEST_ENABLED);
  }
  navUpdateForSCLP.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_SUMMARY
  ].enabled = !getFeatureFlag(flagNames.SCLP_TEST_ENABLED)
    || getFeatureFlag(flagNames.LOAN_AND_CONTRIBUTION_MERGE_TEST_ENABLED);
  return navUpdateForSCLP;
};

const setPagesForLoanContributionTest = navUpdate => {
  const navUpdateForLoanContributionTest = navUpdate;
  // Financial Plan Estimate Download
  if (navUpdate
    .pages[MAIN_STEP_INDEXES.FINANCIAL_PLAN]
    .secondarySteps
    .findIndex(t => t.name === 'financialEstimate')
    === SECONDARY_STEP_INDEXES.FINANCIAL_SUMMARY_DOWNLOAD) {
    if (getFeatureFlag(flagNames.LOAN_AND_CONTRIBUTION_MERGE_TEST_ENABLED)) {
      navUpdateForLoanContributionTest.pages[
        MAIN_STEP_INDEXES.FINANCIAL_PLAN
      ].secondarySteps[
        SECONDARY_STEP_INDEXES.FINANCIAL_PERSONAL
      ].enabled = false;

      // This navigation step is being used for the merge test.
      navUpdateForLoanContributionTest.pages[
        MAIN_STEP_INDEXES.FINANCIAL_PLAN
      ].secondarySteps[
        SECONDARY_STEP_INDEXES.FINANCIAL_LOAN
      ].enabled = true;
      navUpdateForLoanContributionTest.pages[
        MAIN_STEP_INDEXES.FINANCIAL_PLAN
      ].secondarySteps[
        SECONDARY_STEP_INDEXES.FINANCIAL_LOAN
      ].label = 'Contributions';
      navUpdateForLoanContributionTest.pages[
        MAIN_STEP_INDEXES.FINANCIAL_PLAN
      ].secondarySteps[
        SECONDARY_STEP_INDEXES.FINANCIAL_LOAN
      ].link = '/admissions/financial/contributions';
    } else {
      navUpdateForLoanContributionTest.pages[
        MAIN_STEP_INDEXES.FINANCIAL_PLAN
      ].secondarySteps[
        SECONDARY_STEP_INDEXES.FINANCIAL_LOAN
      ].label = 'Loan';
      navUpdateForLoanContributionTest.pages[
        MAIN_STEP_INDEXES.FINANCIAL_PLAN
      ].secondarySteps[
        SECONDARY_STEP_INDEXES.FINANCIAL_LOAN
      ].link = '/admissions/financial/loan';
    }
  }
  return navUpdateForLoanContributionTest;
};

export const buildCYOPFinancialRoutingLogic = ({
  navigation,
  suppressFinancialMilitary,
  suppressFinancialGrants,
  suppressFinancialLoan,
  preferences = {},
}) => {
  let navUpdate = copyNavigationObject(navigation);

  if (typeof suppressFinancialMilitary === 'boolean') {
    // Military
    navUpdate.pages[
      MAIN_STEP_INDEXES.FINANCIAL_PLAN
    ].secondarySteps[
      SECONDARY_STEP_INDEXES.FINANCIAL_MILITARY
    ].enabled = !!preferences.militarySelected && !suppressFinancialMilitary;
  }

  if (typeof suppressFinancialGrants === 'boolean') {
    // Grants
    navUpdate.pages[
      MAIN_STEP_INDEXES.FINANCIAL_PLAN
    ].secondarySteps[
      SECONDARY_STEP_INDEXES.FINANCIAL_GRANTS
    ].enabled = !!preferences.federalGrantsSelected && !suppressFinancialGrants;
  }

  // Employer Benefits
  navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_EMPLOYER
  ].enabled = !!preferences.employerBenefitsSelected;

  // Discounts
  navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_DISCOUNTS
  ].enabled = !!preferences.employerDiscountsSelected;

  // Scholarships
  navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_SCHOLARSHIPS
  ].enabled = !!preferences.scholarshipsSelected;

  // Tribal
  navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_TRIBAL
  ].enabled = !!preferences.tribalBenefitsSelected;

  // Benefits (Funding Sources) (Disabled because the above pages replace it)
  navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_FUNDING_SOURCES
  ].enabled = false;

  // If loans is not explicitely suppressed we disable it if grants are not selected
  if (suppressFinancialLoan !== 'skip') {
    let suppressFinancialLoanWithSafety = !!suppressFinancialLoan;
    if (typeof suppressFinancialLoan !== 'boolean' && !preferences.federalGrantsSelected) {
      suppressFinancialLoanWithSafety = true;
    }
    // Loans
    navUpdate.pages[
      MAIN_STEP_INDEXES.FINANCIAL_PLAN
    ].secondarySteps[
      SECONDARY_STEP_INDEXES.FINANCIAL_LOAN
    ].enabled = !suppressFinancialLoanWithSafety;
  }

  navUpdate = setPagesForSCLPTest(navUpdate);
  navUpdate = setPagesForLoanContributionTest(navUpdate);

  return navUpdate;
};

const didNavStepsEnabledChange = (existingNavigation, updatedNavigation) => {
  let didChange = false;
  existingNavigation.pages.forEach((mainStep, mainIndex) => {
    if (updatedNavigation.pages?.[mainIndex]?.enabled !== mainStep.enabled) {
      didChange = true;
    }
    mainStep.secondarySteps.forEach((secondaryStep, secondaryIndex) => {
      if (updatedNavigation.pages?.[mainIndex]?.secondarySteps?.[secondaryIndex]?.enabled
        !== secondaryStep.enabled) {
        didChange = true;
      }
    });
  });

  return didChange;
};

export const buildFinancialRoutingLogic = stateValues => {
  const routingLogic = {
    existingNavigation: stateValues.navigation,
    updatedNavigation: {},
    isUpdateRequired: false,
  };
  routingLogic.updatedNavigation = buildCYOPFinancialRoutingLogic(stateValues);

  routingLogic.isUpdateRequired = didNavStepsEnabledChange(
    routingLogic.existingNavigation,
    routingLogic.updatedNavigation,
  );

  return routingLogic;
};

export const isNewNavigationEnabled = application => {
  const { additionalAttributes } = application.application;
  const { enableNewNavigation } = additionalAttributes || {};
  if (getFeatureFlag(flagNames.ENABLE_NAVIGATION_SWITCH)
    && enableNewNavigation && application.navigation?.id) {
    return true;
  }
  return false;
};

// replace by new navigation
export const getBackwardsCompatibleNav = reduxState => {
  const nav = appNav();
  const hasNursing = reduxState.application?.application?.areaOfInterest === 'Nursing';
  const hasCollege = reduxState.person?.information?.hasCollegeInfo === 'True';
  const suppressTranscript = getSuppressAdmissionTranscript(reduxState);
  const suppressSecondaryEd = getSuppressAdmissionSecondary(reduxState);
  const suppressFinancialMilitary = reduxState.application?.suppressFinancialMilitaryApplication;
  const suppressFinancialGrants = reduxState.application?.suppressFinancialGrantsApplication;
  const suppressFinancialLoan = reduxState.application?.suppressFinancialLoanApplication;
  const preferences = reduxState.preferences?.choosePath?.value || {};

  nav.pages[
    MAIN_STEP_INDEXES.PROGRAM
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.LICENSE_INFO
  ].enabled = !!hasNursing;

  nav.pages[
    MAIN_STEP_INDEXES.EDUCATION
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.EXPULSION
  ].enabled = !!hasCollege;

  nav.pages[
    MAIN_STEP_INDEXES.EDUCATION
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.TRANSCRIPT
  ].enabled = !suppressTranscript;

  nav.pages[
    MAIN_STEP_INDEXES.EDUCATION
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.SECONDARY
  ].enabled = !suppressSecondaryEd;

  const {
    updatedNavigation,
  } = buildFinancialRoutingLogic({
    navigation: nav,
    suppressFinancialMilitary,
    suppressFinancialGrants,
    suppressFinancialLoan,
    preferences,
  });

  return updatedNavigation;
};

export const getBackwardsCompatibleApplication = reduxState => {
  if (!reduxState.application?.application?.navigation?.pages) {
    const rebuiltApplication = {
      ...reduxState.application,
    };
    if (rebuiltApplication.application) {
      rebuiltApplication.application.navigation = getBackwardsCompatibleNav(reduxState);
    }
    return rebuiltApplication;
  }
  return reduxState.application;
};

export const getNavigationResetFactors = reduxState => {
  const {
    isCbeDaProgram, isGraduateStudent, isTitleIvEligible, isPrevWorkExpProgram,
  } = getProgramFinancialOptions(reduxState);

  const navigationResetFactors = {
    hasNursing: reduxState.application?.application?.areaOfInterest === 'Nursing',
    hasCollege: reduxState.person?.information?.hasCollegeInfo === 'True',
    hasSSN: hasSSN(reduxState),
    hasIRN: !!getIrn(reduxState),
    hasHigherEds: reduxState.higherEds?.higherEds?.length > 0,
    hasEsignHigherEds: reduxState.higherEds?.esignHigherEds?.length > 0,
    isCbeDaProgram,
    isGraduateStudent,
    isTitleIvEligible,
    isPrevWorkExpProgram,
    isMilitary: getIsMilitary(reduxState),
    preferences: reduxState.preferences?.choosePath?.value || {},
    showExpulsion: false,
  };
  return navigationResetFactors;
};

export const resetEnabilityOfNavigation = (
  navigation,
  navigationResetFactors,
  resetNursingLicense,
  resetExpulsion,
  resetTranscript,
  resetWork,
  resetFinancialMilitary,
  resetFinancialGrants,
  resetFinancialLoan,
) => {
  let navUpdate = copyNavigationObject(navigation);
  const { preferences } = navigationResetFactors;
  // Employer Benefits
  navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_EMPLOYER
  ].enabled = !!preferences.employerBenefitsSelected;

  // Discounts
  navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_DISCOUNTS
  ].enabled = !!preferences.employerDiscountsSelected;

  // Scholarships
  navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_SCHOLARSHIPS
  ].enabled = !!preferences.scholarshipsSelected;

  // Tribal
  navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_TRIBAL
  ].enabled = !!preferences.tribalBenefitsSelected;

  // Benefits (Funding Sources) (Disabled because the above pages replace it)
  navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_FUNDING_SOURCES
  ].enabled = false;

  if (!navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_EMPLOYER
  ].enabled) {
    navUpdate.pages[
      MAIN_STEP_INDEXES.FINANCIAL_PLAN
    ].secondarySteps[
      SECONDARY_STEP_INDEXES.FINANCIAL_EMPLOYER
    ].visited = false;
  }
  if (!navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_DISCOUNTS
  ].enabled) {
    navUpdate.pages[
      MAIN_STEP_INDEXES.FINANCIAL_PLAN
    ].secondarySteps[
      SECONDARY_STEP_INDEXES.FINANCIAL_DISCOUNTS
    ].visited = false;
  }
  if (!navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_SCHOLARSHIPS
  ].enabled) {
    navUpdate.pages[
      MAIN_STEP_INDEXES.FINANCIAL_PLAN
    ].secondarySteps[
      SECONDARY_STEP_INDEXES.FINANCIAL_SCHOLARSHIPS
    ].visited = false;
  }
  if (!navUpdate.pages[
    MAIN_STEP_INDEXES.FINANCIAL_PLAN
  ].secondarySteps[
    SECONDARY_STEP_INDEXES.FINANCIAL_TRIBAL
  ].enabled) {
    navUpdate.pages[
      MAIN_STEP_INDEXES.FINANCIAL_PLAN
    ].secondarySteps[
      SECONDARY_STEP_INDEXES.FINANCIAL_TRIBAL
    ].visited = false;
  }
  if (resetNursingLicense) {
    const suppressNursingLicense = !navigationResetFactors.hasNursing;
    navUpdate.pages[
      MAIN_STEP_INDEXES.PROGRAM
    ].secondarySteps[
      SECONDARY_STEP_INDEXES.LICENSE_INFO
    ].enabled = !suppressNursingLicense;
    if (suppressNursingLicense) {
      navUpdate.pages[
        MAIN_STEP_INDEXES.PROGRAM
      ].secondarySteps[
        SECONDARY_STEP_INDEXES.LICENSE_INFO
      ].visited = false;
    }
  }
  if (resetExpulsion) {
    const suppressExpulsion = !navigationResetFactors.showExpulsion;
    navUpdate.pages[
      MAIN_STEP_INDEXES.EDUCATION
    ].secondarySteps[
      SECONDARY_STEP_INDEXES.EXPULSION
    ].enabled = !suppressExpulsion;
    if (suppressExpulsion) {
      navUpdate.pages[
        MAIN_STEP_INDEXES.EDUCATION
      ].secondarySteps[
        SECONDARY_STEP_INDEXES.EXPULSION
      ].visited = false;
    }
  }
  if (resetTranscript) {
    const suppressTranscript = !navigationResetFactors.hasEsignHigherEds
      || !navigationResetFactors.hasIRN || !navigationResetFactors.hasSSN;
    navUpdate.pages[
      MAIN_STEP_INDEXES.EDUCATION
    ].secondarySteps[
      SECONDARY_STEP_INDEXES.TRANSCRIPT
    ].enabled = !suppressTranscript;
    if (suppressTranscript) {
      navUpdate.pages[
        MAIN_STEP_INDEXES.EDUCATION
      ].secondarySteps[
        SECONDARY_STEP_INDEXES.TRANSCRIPT
      ].visited = false;
    }
  }
  if (resetWork) {
    navUpdate.pages[
      MAIN_STEP_INDEXES.WORK
    ].secondarySteps[
      SECONDARY_STEP_INDEXES.WORK
    ].visited = false;
  }
  if (resetFinancialMilitary) {
    const suppressFinancialMilitary = navigationResetFactors.isCbeDaProgram
      || !navigationResetFactors.isMilitary;
    let enableFiancialMilitary = !suppressFinancialMilitary;
    enableFiancialMilitary = enableFiancialMilitary && !!preferences.militarySelected;
    if (typeof enableFiancialMilitary === 'boolean') {
      // Military
      navUpdate.pages[
        MAIN_STEP_INDEXES
          .FINANCIAL_PLAN
      ].secondarySteps[
        SECONDARY_STEP_INDEXES.FINANCIAL_MILITARY
      ].enabled = enableFiancialMilitary;
      if (!enableFiancialMilitary) {
        navUpdate.pages[
          MAIN_STEP_INDEXES
            .FINANCIAL_PLAN
        ].secondarySteps[
          SECONDARY_STEP_INDEXES.FINANCIAL_MILITARY
        ].visited = false;
      }
    }
  }
  if (resetFinancialGrants) {
    const suppressFinancialGrants = getFeatureFlag(flagNames.NO_SSN_GRANTS_ENABLED)
      ? (
        // Suppress Grants Page if:
        !navigationResetFactors.isTitleIvEligible // program is not Title IV Eligible
        || navigationResetFactors.isCbeDaProgram // program is CBE DA
        || navigationResetFactors.isGraduateStudent // is a Graduate Student
        // Handle the missing ssn with an alert on the page
      ) : (
        // Suppress Grants Page if:
        !navigationResetFactors.isTitleIvEligible // program is not Title IV Eligible
        || navigationResetFactors.isCbeDaProgram // program is CBE DA
        || navigationResetFactors.isGraduateStudent // is a Graduate Student
        || !navigationResetFactors.hasSSN // user has not provided an ssn
      );
    let enableFinancialGrants = !suppressFinancialGrants;
    enableFinancialGrants = enableFinancialGrants && !!preferences.federalGrantsSelected;
    // Grants
    if (typeof enableFinancialGrants === 'boolean') {
      navUpdate.pages[
        MAIN_STEP_INDEXES.FINANCIAL_PLAN
      ].secondarySteps[
        SECONDARY_STEP_INDEXES.FINANCIAL_GRANTS
      ].enabled = enableFinancialGrants;
      if (!enableFinancialGrants) {
        navUpdate.pages[
          MAIN_STEP_INDEXES.FINANCIAL_PLAN
        ].secondarySteps[
          SECONDARY_STEP_INDEXES.FINANCIAL_GRANTS
        ].visited = false;
      }
    }
  }
  if (resetFinancialLoan) {
    const suppressFinancialLoan = !navigationResetFactors.isTitleIvEligible
      || navigationResetFactors.isCbeDaProgram;
    let enableFinancialLoan = !suppressFinancialLoan;
    let suppressFinancialLoanWithSafety = !!suppressFinancialLoan;
    if (typeof suppressFinancialLoan !== 'boolean' && !preferences.federalGrantsSelected) {
      suppressFinancialLoanWithSafety = true;
      enableFinancialLoan = !suppressFinancialLoanWithSafety;
    }
    if (typeof suppressFinancialLoan === 'boolean') {
      // Loans
      navUpdate.pages[
        MAIN_STEP_INDEXES.FINANCIAL_PLAN
      ].secondarySteps[
        SECONDARY_STEP_INDEXES.FINANCIAL_LOAN
      ].enabled = enableFinancialLoan;
      if (!enableFinancialLoan) {
        navUpdate.pages[
          MAIN_STEP_INDEXES.FINANCIAL_PLAN
        ].secondarySteps[
          SECONDARY_STEP_INDEXES.FINANCIAL_LOAN
        ].visited = false;
      }
    }
  }
  navUpdate = setPagesForSCLPTest(navUpdate);
  navUpdate = setPagesForLoanContributionTest(navUpdate);
  return navUpdate;
};

export const skipNavPage = (
  navigation,
  mainStepIndex,
  secondaryStepIndex,
) => {
  const navUpdate = copyNavigationObject(navigation);
  navUpdate.pages[
    mainStepIndex
  ].secondarySteps[
    secondaryStepIndex
  ].enabled = false;
  navUpdate.pages[
    mainStepIndex
  ].secondarySteps[
    secondaryStepIndex
  ].visited = false;
  return navUpdate;
};

export const updateNavPage = (
  navigation,
  mainStepIndex,
  secondaryStepIndex,
  enabled,
  visited,
) => {
  const { pages } = navigation;
  const enabledBefore = pages[
    mainStepIndex
  ].secondarySteps[
    secondaryStepIndex
  ].enabled;
  if (visited === null && enabledBefore === enabled) {
    return navigation;
  }
  pages[
    mainStepIndex
  ].secondarySteps[
    secondaryStepIndex
  ].enabled = enabled;
  if (!enabled) {
    pages[
      mainStepIndex
    ].secondarySteps[
      secondaryStepIndex
    ].visited = false;
  } else {
    pages[
      mainStepIndex
    ].secondarySteps[
      secondaryStepIndex
    ].visited = visited;
  }
  return {
    ...navigation,
    pages,
  };
};

const getResetAutoHopPages = key => {
  const autoHopMap = autoHopNavMap();
  const resetPageListItem = autoHopMap.filter(item => item.key === key)[0];
  return resetPageListItem ? resetPageListItem.secondarySteps : [];
};

const getDisplayNameOnJumpModal = label => {
  switch (label) {
    case 'License Info':
      return 'RN License Info';
    case 'Secondary':
      return 'Secondary Education';
    case 'Higher':
      return 'Higher Education';
    case 'Credits':
    case 'Exam credits':
      return 'Test Credits';
    case 'Transcript':
      return 'Transcripts';
    default:
      return label;
  }
};

const sortPages = (navigation, needRevisitPages, referredRevisitPages) => {
  const sortedPages = [];
  navigation.pages.every(page => {
    let shouldStop = false;
    page.secondarySteps.every(secondaryStep => {
      const index = needRevisitPages.indexOf(secondaryStep.name);
      const { enabled } = secondaryStep;
      if (index > -1 && enabled) {
        let index2 = -1;
        if (referredRevisitPages) {
          index2 = referredRevisitPages.indexOf(secondaryStep.label);
        }
        if (index2 === -1) {
          if (secondaryStep.label) {
            sortedPages.push(getDisplayNameOnJumpModal(secondaryStep.label));
          }
        }
      }
      if (needRevisitPages.length === sortedPages.length) {
        shouldStop = true;
        return false;
      }
      return true;
    });
    if (shouldStop) {
      return false;
    }
    return true;
  });
  return sortedPages;
};

export const removeVisitedPages = (navigation, updatedFields) => {
  const updateNavigation = copyNavigationObject(navigation);
  updatedFields.forEach(field => {
    const resetPageList = getResetAutoHopPages(field);
    let mainStepIndex = -1;
    let secondaryStepIndex = -1;
    updateNavigation.pages.forEach((page, index) => {
      page.secondarySteps.forEach((secondaryStep, secondIndex) => {
        if (resetPageList
          && resetPageList.length > 0 && resetPageList.includes(secondaryStep.name)) {
          mainStepIndex = index;
          secondaryStepIndex = secondIndex;
          if (mainStepIndex > -1 && secondaryStepIndex > -1) {
            updateNavigation.pages[
              mainStepIndex
            ].secondarySteps[
              secondaryStepIndex
            ].visited = false;
          }
        }
      });
    });
  });
  return updateNavigation;
};

export const resetVisibilityOfNavigation = (
  //  visitedPageName,
  navigation,
  updatedFields,
) => {
  if (updatedFields && Array.isArray(updatedFields) && updatedFields.length > 0) {
    return removeVisitedPages(navigation, updatedFields);
  }
  return navigation;
};

export const getRevisitPages = (
  navigation,
  updatedFields,
) => {
  let needRevisitPages = [];
  const { lastVisitedPage } = navigation;
  let lastVisitedPageIndex = 100;
  let lastVisitedPageSecondIndex = 100;
  let needRevisitFinanical = false;
  updatedFields.forEach(field => {
    const resetPageList = getResetAutoHopPages(field);
    if (resetPageList && resetPageList.length > 0) {
      navigation.pages.every((page, index) => {
        let shouldStop = false;
        page.secondarySteps.every((secondaryStep, secondIndex) => {
          const {
            name, label, enabled, visited,
          } = secondaryStep;
          if (label === lastVisitedPage) {
            lastVisitedPageIndex = index;
            lastVisitedPageSecondIndex = secondIndex;
          }
          if (index > lastVisitedPageIndex
            || (index === lastVisitedPageIndex && secondIndex > lastVisitedPageSecondIndex)) {
            shouldStop = true;
            return false;
          }
          if (page.enabled && page.label !== 'Financial Plan' && page.label !== 'Review'
            && resetPageList.includes(name)) {
            const revisitPageIndex = needRevisitPages.indexOf(name);
            if (enabled && revisitPageIndex === -1) {
              needRevisitPages.push(name);
            }
          } else if (page.label !== 'Financial Plan' && page.label !== 'Review' && page.enabled && enabled && !visited) {
            needRevisitPages.push(name);
          }
          if (page.enabled && page.label === 'Financial Plan' && resetPageList.includes(name)) {
            needRevisitFinanical = true;
          }
          return true;
        });
        if (shouldStop) {
          return false;
        }
        return true;
      });
    }
  });
  needRevisitPages = sortPages(navigation, needRevisitPages, null);
  if (needRevisitFinanical) {
    if (updatedFields && updatedFields.length === 1 && updatedFields[0] === 'financialChange') {
      needRevisitPages.push('Financial Plan Summary');
    } else {
      needRevisitPages.push('Financial Plan');
    }
  }
  return needRevisitPages;
};

// reuse lastVisitedPage as farthestVisitedPage
export const isFarthestVisitedPage = (navigation, curIndex, curSecondIndex) => {
  let isFarthest = false;
  if (navigation.lastVisitedPage) {
    navigation.pages.forEach((page, index) => {
      page.secondarySteps.forEach((secondaryStep, secondIndex) => {
        const { label } = navigation.pages[index].secondarySteps[secondIndex];
        if (label === navigation.lastVisitedPage
          && (curIndex > index || (curIndex === index && curSecondIndex > secondIndex))) {
          isFarthest = true;
        }
      });
    });
  } else {
    isFarthest = true;
  }
  return isFarthest;
};

export const updateFarthestVisitedPage = (navigation, curIndex, curSecondIndex) => {
  const updateNav = copyNavigationObject(navigation);
  if (isFarthestVisitedPage(navigation, curIndex, curSecondIndex)) {
    const { label } = navigation.pages[
      curIndex
    ].secondarySteps[
      curSecondIndex
    ];
    updateNav.lastVisitedPage = label;
  }
  return updateNav;
};

export const getApplicationNavigation = (application, newNavigationEnabled) => {
  if (newNavigationEnabled || isNewNavigationEnabled(application)) {
    return application.navigation;
  }
  return application.application.navigation;
};

export const getAppNavigation = (reduxState, newNavigationEnabled) => {
  if (newNavigationEnabled) {
    return reduxState.application.navigation;
  }
  return getBackwardsCompatibleApplication(reduxState).application.navigation;
};

export const getApplicationNavigationPages = (application, newNavigationEnabled) => {
  if (newNavigationEnabled) {
    return application.navigation?.pages;
  }
  return application.application.navigation?.pages;
};

const addNonNavPageLinks = (arrayOfPages, reduxState) => {
  const updatedPages = [];
  const showSexualOrientation = CA_PROG_ELIGIBLE_SEXUAL_ORIENTATION
    .includes(reduxState.programOfferingSearch?.programOfferingItem?.programId)
    && reduxState.programOfferingSearch?.programOfferingItem?.state === 'CA';
  const showProgramDisclosure = (reduxState.contact?.address || []).some(
    address => (address?.stateProvince || '').toLowerCase() === 'ma',
  );

  const application = reduxState.application?.application;
  const { applicationType } = application;
  const newNavigationEnabled = isNewNavigationEnabled(reduxState.application);
  arrayOfPages.forEach((page, index) => {
    updatedPages.push(page);
    switch (page.name) {
      case 'financial':
        if (applicationType !== 'PROGRAM CHANGE') {
          if (!newNavigationEnabled) {
            updatedPages.push({
              link: '/admissions/financial/choose-your-path',
            });
          }
        }
        break;
      case 'work':
        if (!(arrayOfPages[index + 1].name === 'financial') && applicationType !== 'PROGRAM CHANGE') {
          if (!newNavigationEnabled) {
            updatedPages.push({
              link: '/admissions/financial/choose-your-path',
            });
          }
        }
        break;
      case 'financialLoans':
        if (!reduxState.application.suppressFinancialParentPlusLoanApplication) {
          updatedPages.push({
            link: '/admissions/financial/loan/parentplus',
          });
        }
        if (!reduxState.application.suppressFinancialGradPlusLoanApplication) {
          updatedPages.push({
            link: '/admissions/financial/loan/gradplus',
          });
        }
        break;
      case 'reviewAcknowledge':
        if (showSexualOrientation || showProgramDisclosure) {
          updatedPages.push({
            link: '/admissions/review/acknowledgements/additional',
          });
        }
        break;
      default:
    }
  });
  return updatedPages;
};

const flattenArray = arr => {
  let flattened = [];
  for (let i = 0; i < arr.length; i += 1) {
    if (Array.isArray(arr[i])) {
      flattened = flattened.concat(flattenArray(arr[i]));
    } else {
      flattened.push(arr[i]);
    }
  }
  return flattened;
};

const allEnabledPages = (reduxState, newNavigationEnabled) => {
  // const navigation = reduxState.application?.application?.navigation?.pages
  //   ? reduxState.application?.application?.navigation
  //   : getBackwardsCompatibleNav(reduxState);
  const navigation = getAppNavigation(reduxState, newNavigationEnabled);

  // Filter out disabled pages
  const pages = [];
  navigation.pages.forEach(section => {
    if (section.enabled) {
      const page = section.secondarySteps.filter(secStep => secStep.enabled === true);
      pages.push(page);
    }
  });

  // Create list of enabled pages
  return addNonNavPageLinks(flattenArray(pages), reduxState);
};

const allEnabledPagesOverride = overrideAppNavigation => {
  // Filter out disabled pages
  const pages = [];
  overrideAppNavigation.forEach(section => {
    if (section.enabled) {
      const page = section.secondarySteps.filter(secStep => secStep.enabled === true);
      pages.push(page);
    }
  });

  // Create list of enabled pages
  return flattenArray(pages);
};

const findNextPageOfCurrentPage = enabledPages => {
  let urlPath = '';
  const currentPathSplit = window.location.pathname.split('/');
  const currentPgName = currentPathSplit.pop();
  const currentPgParentName = currentPathSplit.pop();
  // search for the current page and grab the next page
  const hasCurrentPgName = page => {
    const splitPageLink = page.link.split('/');
    const pgName = splitPageLink.pop();
    const pgParentName = splitPageLink.pop();
    return pgName === currentPgName && pgParentName === currentPgParentName;
  };
  const pageArrIndex = enabledPages.findIndex(hasCurrentPgName);

  if (pageArrIndex === -1) {
    return urlPath;
  }

  const nextLink = enabledPages.slice(pageArrIndex + 1, pageArrIndex + 2);
  urlPath = nextLink[0]?.link;
  return urlPath;
};

const findClosestNotVisitedPage = enabledPages => {
  let urlPath = '';
  let needFindNextPage = false;
  if (window.location.href.includes('/review') || window.location.href.includes('/acknowledgements') || window.location.href.includes('/sign-and-submit')) {
    needFindNextPage = true;
  }
  const firstNotVisitPage = enabledPages.find(page => page.enabled && !page.visited);
  if (firstNotVisitPage) {
    urlPath = firstNotVisitPage.link;
    if (!window.location.href.includes('/review') && !window.location.href.includes('/acknowledgements') && !window.location.href.includes('/sign-and-submit')
      && (urlPath.includes('acknowledgements') || urlPath.includes('sign-and-submit'))) {
      const reviewPage = enabledPages.find(page => page.name === 'review');
      urlPath = reviewPage.link;
    }
  } else {
    needFindNextPage = true;
  }
  if (needFindNextPage) {
    urlPath = findNextPageOfCurrentPage(enabledPages);
  }
  return urlPath;
};

export const nextPageLinkOverride = overrideAppNavigation => {
  // Array of all enabled pages
  const enabledPages = allEnabledPagesOverride(overrideAppNavigation.pages);
  if (enabledPages.length !== 0) {
    let urlPath = '';
    if (overrideAppNavigation.id) {
      // find the closest not visit
      urlPath = findClosestNotVisitedPage(enabledPages);
    } else {
      urlPath = findNextPageOfCurrentPage(enabledPages);
    }
    if (urlPath) {
      return urlPath;
    }
    return '/admissions';
  }
  return '/admissions';
};

export const nextPageOverride = overrideAppNavigation => {
  // Array of all enabled pages
  const enabledPages = allEnabledPagesOverride(overrideAppNavigation.pages);
  if (enabledPages.length !== 0) {
    let urlPath = '';
    if (overrideAppNavigation.id) {
      // find the closest not visit
      urlPath = findClosestNotVisitedPage(enabledPages);
    } else {
      urlPath = findNextPageOfCurrentPage(enabledPages);
    }
    if (urlPath) {
      const urlSection = urlPath.split('/');
      urlSection.shift();
      urlSection.shift();
      return urlSection.join('/');
    }
    return '/admissions';
  }
  return '/admissions';
};

export const nextPage = (reduxState, newNavigationEnabled) => {
  // Array of all enabled pages
  const enabledPages = allEnabledPages(reduxState, newNavigationEnabled);
  if (enabledPages.length !== 0) {
    let urlPath = '';
    if (newNavigationEnabled) {
      // find the closest not visit
      urlPath = findClosestNotVisitedPage(enabledPages);
    } else {
      urlPath = findNextPageOfCurrentPage(enabledPages);
    }

    if (urlPath !== undefined) {
      const urlSection = urlPath.split('/');
      urlSection.shift();
      urlSection.shift();
      return urlSection.join('/');
    }
    return '/admissions';
  }
  return '/admissions';
};

export const previousPage = (reduxState, newNavigationEnabled) => {
  // Array of all enabled pages
  const enabledPages = allEnabledPages(reduxState, newNavigationEnabled);
  if (enabledPages.length !== 0) {
    // Grab last element of pathname
    const currentPathSplit = window.location.pathname.split('/');
    const currentPgName = currentPathSplit.pop();
    const currentPgParentName = currentPathSplit.pop();
    // search for the current page and grab the previous page
    const hasCurrentPgName = page => {
      const splitPageLink = page.link.split('/');
      const pgName = splitPageLink.pop();
      const pgParentName = splitPageLink.pop();
      return pgName === currentPgName && pgParentName === currentPgParentName;
    };
    const pageArrIndex = enabledPages.findIndex(hasCurrentPgName);
    if (pageArrIndex === -1) {
      return '/admissions';
    }
    const backLink = enabledPages.slice(pageArrIndex - 1, pageArrIndex);
    const urlPath = backLink[0]?.link;

    if (urlPath !== undefined) {
      const urlSection = urlPath.split('/');
      urlSection.shift();
      urlSection.shift();
      return urlSection.join('/');
    }
    return '/admissions';
  }
  return '/admissions';
};

const getClosestPrecedingEnabledStepLink = (steps, currentIndex) => {
  let workingIndex = currentIndex;
  while (workingIndex > 0) {
    workingIndex -= 1;
    if (steps[workingIndex].enabled) {
      return steps[workingIndex].link;
    }
  }
  return '/admissions/personal/more';
};

export const getNextPageFromNewNavigation = (navigation, newNavigationEnabled) => {
  let nextNavPage = '';
  if (newNavigationEnabled) {
    navigation.pages.forEach(section => {
      if (section.enabled) {
        const page = section.secondarySteps.filter(secStep => secStep.enabled && !secStep.visited);
        if (page && page.length > 0 && !nextNavPage) {
          nextNavPage = page[0].link;
        }
      }
    });
  }
  return nextNavPage;
};

export const getDefaultRoute = (
  navigation,
  nextSagaTaskRefName,
  esignTranscriptTaskStatus,
  hasEsignableColleges,
  suppressTranscript,
  hasSecondaryEd,
  suppressSecondaryEd,
  needVisitHigherEd,
  suppressFinancialMilitary,
  newNavigationEnabled,
  taskPageEnabled,
) => {
  if (newNavigationEnabled) {
    const newNavReturnPage = getNextPageFromNewNavigation(navigation, newNavigationEnabled);
    if (newNavReturnPage) {
      if (newNavReturnPage.endsWith('acknowledgements') || newNavReturnPage.endsWith('sign-and-submit')) {
        return '/admissions/review';
      }
      return newNavReturnPage;
    }
  }

  let returnPage = '';
  // Retain original redirection logic for applicants who are in
  // review section or finished their app
  if (taskPageEnabled && (nextSagaTaskRefName === 'provisionPostSubmitTasks' || nextSagaTaskRefName === 'advisorReviewAwait' || nextSagaTaskRefName === 'advisorReview' || nextSagaTaskRefName === 'join2' || nextSagaTaskRefName === 'call_dw__2')) {
    return '/admissions/review/transition';
  }
  if (nextSagaTaskRefName === 'reviewAcknowledge' || nextSagaTaskRefName === 'reviewSignSubmit'
    || nextSagaTaskRefName === 'provisionPostSubmitTasks' || nextSagaTaskRefName === 'advisorReviewAwait'
    || nextSagaTaskRefName === 'advisorReview' || nextSagaTaskRefName === 'join2') {
    returnPage = 'review';
  }

  if (!suppressSecondaryEd && (returnPage === 'review' || secondaryEdResetPages.includes(nextSagaTaskRefName))) {
    if (!hasSecondaryEd) {
      // need revisit secondary-education page
      returnPage = 'education/secondary-education';
    }
  }

  if (returnPage === '' && needVisitHigherEd && (returnPage === 'review' || higherEdResetPages.includes(nextSagaTaskRefName))) {
    // need revisit secondary-education page
    returnPage = 'education/higher-education';
  }

  if (returnPage === '' && !suppressTranscript && (returnPage === 'review' || transcriptResetPages.includes(nextSagaTaskRefName))) {
    if (hasEsignableColleges && !esignTranscriptTaskStatus) {
      // need revisit transcript page
      returnPage = 'education/transcripts';
    }
  }

  if (returnPage !== '') {
    return `/admissions/${returnPage}`;
  }
  const flattenedListOfPages = [];
  navigation.pages.forEach(
    page => page.secondarySteps.forEach(
      secondaryStep => flattenedListOfPages.push({ ...secondaryStep }),
    ),
  );

  const indexOfNextStep = flattenedListOfPages.findIndex(page => page.name === nextSagaTaskRefName);
  if (indexOfNextStep === -1) {
    return '/admissions/personal/more';
  }

  if (flattenedListOfPages[indexOfNextStep].enabled) {
    if (nextSagaTaskRefName !== 'financialMilitary' || (
      nextSagaTaskRefName === 'financialMilitary' && !suppressFinancialMilitary)
    ) {
      return flattenedListOfPages[indexOfNextStep].link;
    }
  }

  return getClosestPrecedingEnabledStepLink(flattenedListOfPages, indexOfNextStep);
};

export const mobileNavigation = (enabledSections, sectionNameIndex, currrentPgPathInfo, tasks) => {
  // Define previous pages with anchor
  let pageIndex;
  // Create copy of navigation pages obj
  const updatedMobileNav = copyNavPagesObject(enabledSections);
  enabledSections.forEach((section, i) => {
    // Section status
    if (i < sectionNameIndex) {
      updatedMobileNav[i].sectionStatus = 'complete';
      updatedMobileNav[i].sectionADAstatus = `${section.label} section is complete`;
    } else if (i === sectionNameIndex) {
      updatedMobileNav[i].sectionStatus = 'current';
      updatedMobileNav[i].sectionADAstatus = `${section.label} is your current section`;
    } else if (i > sectionNameIndex) {
      updatedMobileNav[i].sectionStatus = '';
      updatedMobileNav[i].sectionADAstatus = `${section.label} section is incomplete`;
    }
    section.secondarySteps.forEach((page, ii) => {
      // Current page
      if (page.link.endsWith(currrentPgPathInfo)) {
        pageIndex = ii;
        updatedMobileNav[i].secondarySteps[ii].currentPg = true;
      } else {
        // For those financial/review pages not in the navigation object
        // eslint-disable-next-line no-lonely-if
        if (currrentPgPathInfo === '/choose-your-path') {
          pageIndex = 0;
          updatedMobileNav[
            MAIN_STEP_INDEXES.FINANCIAL_PLAN
          ].secondarySteps[
            SECONDARY_STEP_INDEXES.FINANCIAL_INTRO
          ].currentPg = true;
        } else if (currrentPgPathInfo === '/secondary') {
          pageIndex = 1;
          updatedMobileNav[
            MAIN_STEP_INDEXES.FINANCIAL_PLAN
          ].secondarySteps[
            SECONDARY_STEP_INDEXES.FINANCIAL_MILITARY
          ].currentPg = true;
        } else if (currrentPgPathInfo === '/parentplus') {
          pageIndex = 9;
          updatedMobileNav[
            MAIN_STEP_INDEXES.FINANCIAL_PLAN
          ].secondarySteps[
            SECONDARY_STEP_INDEXES.FINANCIAL_LOAN
          ].currentPg = true;
        } else if (currrentPgPathInfo === '/gradplus') {
          pageIndex = 9;
          updatedMobileNav[
            MAIN_STEP_INDEXES.FINANCIAL_PLAN
          ].secondarySteps[
            SECONDARY_STEP_INDEXES.FINANCIAL_LOAN
          ].currentPg = true;
        } else if (currrentPgPathInfo === '/additional') {
          pageIndex = 1;
          updatedMobileNav[
            MAIN_STEP_INDEXES.REVIEW - 1 // MANAGE_FUNDS: 5 is not part of the array
          ].secondarySteps[
            SECONDARY_STEP_INDEXES.ACKNOWLEDGE
          ].currentPg = true;
        } else {
          updatedMobileNav[i].secondarySteps[ii].currentPg = false;
        }
      }
      // Set anchor on li
      if (i <= sectionNameIndex) {
        updatedMobileNav[i].secondarySteps[ii].anchor = true;
        // Set automatically workflow to true
        updatedMobileNav[i].hasWorkflow = true;
      } else {
        updatedMobileNav[i].secondarySteps[ii].anchor = false;
      }
      if (i === sectionNameIndex && ii >= pageIndex) {
        updatedMobileNav[i].secondarySteps[ii].anchor = false;
      }
      // Set workflow to hide/show section links
      // only check the first page in section
      if (i >= sectionNameIndex && ii === 0) {
        if (tasks && tasks.some(task => task.referenceTaskName === page.name)) {
          updatedMobileNav[i].hasWorkflow = true;
        } else {
          updatedMobileNav[i].hasWorkflow = false;
        }
      }
    });
  });
  return updatedMobileNav;
};

export const hzStepperCentering = (menu, menuItem) => {
  const hzStepper = document.querySelector(menu);
  const hzCurrentEl = document.querySelector(menuItem);
  if (hzStepper && hzCurrentEl) {
    const hzStepperWidth = hzStepper.offsetWidth; // screen width
    const hzCurrentElWidth = hzCurrentEl.offsetWidth;
    const hzCurrentEloffsetLeft = hzCurrentEl.offsetLeft;
    const hzScrolledToLeft = hzCurrentEloffsetLeft - hzStepperWidth / 2;
    hzStepper.scrollLeft = hzScrolledToLeft + hzCurrentElWidth / 2;
  }
};
export const vtStepperCentering = () => {
  const vtStepper = document.querySelector('.sub-nav');
  const vtCurrentEl = document.querySelector('.sub-group-stepper-current');
  if (vtStepper && vtCurrentEl) {
    const vtStepperWidth = vtStepper.offsetWidth;
    const vtCurrentElWidth = vtCurrentEl.offsetWidth;
    const vtCurrentEloffsetLeft = vtCurrentEl.offsetLeft;
    const vtScrolledToLeft = vtCurrentEloffsetLeft - vtStepperWidth / 2;
    vtStepper.scrollLeft = vtScrolledToLeft + vtCurrentElWidth / 2;
  }
};
