import { FLOPFLIP_STATE_SLICE, createFlopflipReducer } from '@flopflip/react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { loadUser, reducer as oidcReducer } from 'redux-oidc';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
// import { createLogger } from 'redux-logger';
import createThunkMiddleware from 'redux-thunk';
import Services from '../services/index';
import cas from './domains/cas/reducer';
import application from './domains/application/reducer';
import enumReducer from './domains/enum/reducer';
import contact from './domains/contact/reducer';
import document from './domains/document/reducer';
import csrf from './domains/csrf/reducer';
import defaultFlags from './defaultFeatureFlags';
import person from './domains/person/reducer';
import higherEds from './domains/organization/higher/reducer';
import creditsInfo from './domains/person/exam/reducer';
import citizenship from './domains/person/citizenship/reducer';
import certificate from './domains/person/certificate/reducer';
import associations from './domains/person/associations/reducer';
import military from './domains/person/military/reducer';
import secondaryEd from './domains/organization/secondary/reducer';
import employerDiscounts from './domains/organization/employerDiscounts/reducer';
import employerBenefits from './domains/organization/employer/reducer';
import preloadedState from './preloadedState';
import task from './domains/saga/reducer';
import personTasks from './domains/saga/personTasks/reducer';
import user from './domains/iam/reducer';
import wallet from './domains/wallet/reducer';
import userManager from './domains/auth0/userManager';
import programofferingReducer from './domains/programoffering/reducer';
import genesysReducer from './domains/vendor/genesys/reducer';
import calculatorInput from './domains/financialaid/calculatorinput/reducer';
import calculatorSummary from './domains/financialaid/calculatorsummary/reducer';
import militaryBenefits from './domains/financialaid/militarybenefits/reducer';
import preferences from './domains/preferences/reducer';
import student from './domains/student/reducer';
import forgerock from './domains/forgerock/reducer';
// import studentNotification from './domains/financialaid/studentNotification/reducer';
import leads from './domains/leads/reducer';
// DO NOT USE (window.__ENV || {})
export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

// Injection of global services
const thunkWithServices = createThunkMiddleware.withExtraArgument(
  {
    Services,
  },
);

const middlewares = [routerMiddleware(history), thunkWithServices];

// DO NOT USE (window.__ENV || {})
if (process.env.NODE_ENV === 'development') {
  // Turn this on locally if you need logging for redux in the console.
  // Otherwise utilize the redux extension for browser consoles.
  // middlewares.push(createLogger());
}

const store = createStore(
  combineReducers({
    cas,
    csrf,
    person,
    military,
    higherEds,
    secondaryEd,
    creditsInfo,
    user,
    contact,
    application,
    enumReducer,
    document,
    task,
    personTasks,
    citizenship,
    certificate,
    associations,
    [FLOPFLIP_STATE_SLICE]: createFlopflipReducer(defaultFlags),
    router: connectRouter(history),
    oidc: oidcReducer,
    wallet,
    genesys: genesysReducer,
    programOfferingSearch: programofferingReducer,
    calculatorInput,
    calculatorSummary,
    militaryBenefits,
    preferences,
    student,
    forgerock,
    employerDiscounts,
    employerBenefits,
//    studentNotification,
    leads,
  }),
  preloadedState,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

loadUser(store, userManager);

export default store;
