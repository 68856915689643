/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { ReactComponent as LeftBackIcon } from '../../icons/left-long-arrow.svg';
import styles from './styles';

const useStyles = styles;

function BackButton(props) {
  const {
    id,
    ariaLabel,
    disabled,
    handleClick,
    label,
    hrefbackLink,
    linkOnly,
    linkRouterToURL,
    linkRouterBackIcon,
    ...propsToPass
  } = props;
  const classes = useStyles();

  return (
    <>
      {linkRouterToURL ? (
        <Link
          {...propsToPass}
          id={id}
          to={linkRouterToURL}
          aria-label={ariaLabel}
          className={linkRouterBackIcon
            ? 'smallCtaBtn defaultLink linkWithArrow' : 'smallCtaBtn defaultLink'}
        >
          {linkRouterBackIcon ? (
            <LeftBackIcon
              aria-hidden
              className={classes.backIcon}
            />
          ) : null}
          {label}
        </Link>
      ) : (
        <Button
          {...propsToPass}
          id={id}
          role="link"
          aria-label={ariaLabel}
          disabled={disabled}
          className={linkOnly ? 'smallCtaBtn' : 'pageBackBtn'}
          onClick={handleClick}
          startIcon={linkOnly ? null : (
            <LeftBackIcon
              aria-hidden
              className={classes.backIcon}
            />
          )}
          href={hrefbackLink}
        >
          {label}
        </Button>
      )}
    </>
  );
}

BackButton.defaultProps = {
  id: '',
  ariaLabel: '',
  disabled: false,
  label: '',
  handleClick: () => { },
  hrefbackLink: '',
  linkOnly: false,
  linkRouterToURL: null,
  linkRouterBackIcon: true,
};

BackButton.propTypes = {
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  hrefbackLink: PropTypes.string,
  linkOnly: PropTypes.bool,
  linkRouterToURL: PropTypes.string,
  linkRouterBackIcon: PropTypes.bool,
};

export default BackButton;
