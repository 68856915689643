/* eslint-disable react/no-array-index-key */
import React, {
  forwardRef, useState, memo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
  Hidden,
} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import constants from './constants';
import styles from './styles';
import { UOPX_HELP_DESK_PHONE_NUMBER, UOPX_ESI_PHONE_NUMBER } from '../../modules/admissions/constants';
import {
  ReactComponent as ChatGenesysIcon,
} from '../../modules/admissions/components/icons/chatGenesys2.svg';

const useStyles = styles;

// eslint-disable-next-line react/display-name
const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function TechSupport({
  errorCode,
  handleChatClick,
  handleCallClick,
  esiErrorWindow,
}) {
  const classes = useStyles();
  const [open] = useState(true);
  const id = 'tech-support-alert';

  return (
    <Dialog
      id={id}
      data-cy={id}
      maxWidth={false}
      open={open}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      TransitionComponent={Transition}
    >
      <Grid container className={classes.container}>
        <Hidden xsDown>
          <Grid container item sm={5} className={classes.imageContainer}>
            <Grid
              container
              item
              className={classes.image}
              justifyContent="center"
              alignItems="flex-end"
            >
            { esiErrorWindow !== 'true' &&
              <Grid
                container
                item
                className={classes.agentContainer}
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  id={`${id}-agent-name`}
                  data-cy={`${id}-agent-name`}
                  className={classes.agentName}
                >
                  <span style={{ fontWeight: 700 }}>{constants.agentName}</span>
                  <span>{` | ${constants.agentTitle}`}</span>
                </Typography>
              </Grid>
            }
            </Grid>
          </Grid>
        </Hidden>
        <Grid container item xs={12} sm={7} className={classes.secondaryContainer}>
          <DialogContent className={classes.contentContainer}>
            <DialogTitle disableTypography className={classes.titleContainer}>
              <Typography
                id={`${id}-title`}
                data-cy={`${id}-title`}
                className={classes.title}
              >
                {constants.title}
              </Typography>
            </DialogTitle>
            <DialogContentText
              id={`${id}-description`}
              data-cy={`${id}-description`}
              className={classes.description}
            >
              {esiErrorWindow === 'true' ? constants.esiDescription : constants.description}
              {errorCode && errorCode.length ? (
                <div className={classes.errorCodeWrapper}>
                  <Typography className={classes.errorCodeTitle}>
                    {errorCode.length > 1 ? 'Error codes:' : 'Error code:'}
                  </Typography>
                  <Typography className={classes.errorCode}>
                    {errorCode.map((error, index) => (
                      <span key={`error${index}`}>
                        {error}
                        <span> / </span>
                      </span>
                    ))}
                  </Typography>
                </div>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.actions}>
            {handleChatClick && (
            <Button
              id={`${id}-chat-btn`}
              data-cy={`${id}-chat-btn`}
              onClick={handleChatClick}
              className="mainBtn"
              color="secondary"
              disableRipple
            >
              <ChatGenesysIcon className={classes.chatGenesys} />
              {constants.chatButton}
            </Button>
            )}
            <Button
              id={`${id}-phone-btn`}
              data-cy={`${id}-phone-btn`}
              className="mainBtn"
              color="secondary"
              disableRipple
              href={`tel:${(esiErrorWindow === 'true' ? UOPX_ESI_PHONE_NUMBER : UOPX_HELP_DESK_PHONE_NUMBER).replace(/\D/g, '')}`}
              onClick={handleCallClick}
              aria-label={`Call Technical Support at ${esiErrorWindow === 'true' ? UOPX_ESI_PHONE_NUMBER : UOPX_HELP_DESK_PHONE_NUMBER}`}
            >
              <PhoneIcon />
              {esiErrorWindow === 'true' ? UOPX_ESI_PHONE_NUMBER : UOPX_HELP_DESK_PHONE_NUMBER}
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
}

TechSupport.defaultProps = {
  handleChatClick: null,
  errorCode: [],
  handleCallClick: () => {},
  esiErrorWindow: '',
};

TechSupport.propTypes = {
  handleChatClick: PropTypes.func,
  errorCode: PropTypes.arrayOf(PropTypes.string),
  handleCallClick: PropTypes.func,
  esiErrorWindow: PropTypes.string,
};

export default memo(TechSupport);
