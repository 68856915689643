import React from 'react';
import { Grid } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Email';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './styles';

export default function EmailHelp({
  id,
  emailAddress,
  onClickCallback,
  ariaLabel,
  styleOverride,
}) {
  const classes = styles();

  return (
    <Grid
      container
      wrap="nowrap"
      className={clsx(classes.containerGrid, 'emailHelp')}
      style={styleOverride}
    >
      <Grid item>
        <MailIcon
          id={`${id}Icon`}
          className={classes.mailIcon}
          aria-hidden="false"
          role="img"
          aria-label={ariaLabel}
        />
      </Grid>
      <Grid item className={classes.emailItemGrid}>
        <a
          id={id}
          href={`mailto:${emailAddress}`}
          target="_blank"
          className={clsx(classes.emailER, 'smallCtaBtn ellipsisLink')}
          rel="noreferrer"
          title={emailAddress}
          onClick={onClickCallback}
        >
          {emailAddress}
        </a>
      </Grid>
    </Grid>
  );
}

EmailHelp.defaultProps = {
  emailAddress: '',
  onClickCallback: () => {},
  ariaLabel: 'Email the University of Phoenix at',
  styleOverride: {},
  id: 'emailHelp',
};

EmailHelp.propTypes = {
  emailAddress: PropTypes.string,
  onClickCallback: PropTypes.func,
  ariaLabel: PropTypes.string,
  styleOverride: PropTypes.objectOf(
    PropTypes.object,
  ),
  id: PropTypes.string,
};
