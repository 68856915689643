import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  ReactComponent as ChatIcon,
} from '../../modules/admissions/components/icons/chat.svg';
import styles from './styles';

export default function ChatHelp({
  onClickCallback,
  ariaLabel,
  styleOverride,
}) {
  const classes = styles();

  const chatWithUs = () => {
    const genesysChat = document.querySelector('.supportTools #genesysChat');
    if (genesysChat) {
      genesysChat.click();
    }
    onClickCallback();
  };

  return (
    <Grid
      container
      wrap="nowrap"
      className={clsx(classes.containerGrid, 'chatHelp')}
      alignItems="center"
      style={styleOverride}
      id="transition-chat-er-container"
    >
      <Grid item>
        <ChatIcon id="chatHelpIcon" className={classes.chatSupport} />
      </Grid>
      <Grid item>
        <button
          type="button"
          onClick={chatWithUs}
          className={clsx(classes.chatNow, 'defaultCtaBtn')}
          id="transition-chat"
          aria-label={`Chat with us, ${ariaLabel}`}
        >
          Chat with us
        </button>
      </Grid>
    </Grid>
  );
}

ChatHelp.defaultProps = {
  onClickCallback: () => {},
  ariaLabel: 'an enrollment representative can help',
  styleOverride: {},
};

ChatHelp.propTypes = {
  onClickCallback: PropTypes.func,
  ariaLabel: PropTypes.string,
  styleOverride: PropTypes.objectOf(
    PropTypes.object,
  ),
};
