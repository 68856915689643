import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: theme.spacing(2.5),
    padding: theme.spacing(1.2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      marginRight: 0,
      padding: theme.spacing(2),
    },
    '& .chatHelp': {
      marginLeft: theme.spacing(1.1),
    },
  },
  containerGrid: {
    marginBottom: theme.spacing(0.8),
  },
  headingLeadWithMobile: {
    display: 'block',
    fontWeight: '600!important',
    lineHeight: 'unset',
    textTransform: 'uppercase',
    letterSpacing: theme.spacing(0.2),
    color: theme.palette.text.darkBlue,
  },
  headingLead: {
    display: 'none',
    fontWeight: '600!important',
    lineHeight: 'unset',
    textTransform: 'uppercase',
    letterSpacing: theme.spacing(0.2),
    color: theme.palette.text.darkBlue,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  gChatContact: {
    paddingLeft: 0,
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(1.5),
    '& li': {
      display: 'inline-block',
    },
  },
  gCall: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2.5),
    },
  },
  helpSupportIcon: {
    marginBottom: theme.spacing(-1),
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.7),
    width: theme.spacing(5),
    height: theme.spacing(4),
  },
  gChat: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  chatSupport: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginBottom: theme.spacing(-0.9),
  },
  chatNow: {
    background: 'none',
    cursor: 'pointer',
    border: 0,
    fontSize: theme.spacing(1.6),
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    margin: '0 0 0 1rem',
    padding: 0,
    height: theme.spacing(2.5),
    whiteSpace: 'nowrap',
    color: '#30718D!important',
    borderBottomColor: '#30718D!important',
  },
}));
