import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import makeStyles, { styles } from './styles';
import VideoImage from '../videoImage/VideoImage';

const DialogTitle = withStyles(styles)(props => {
  const {
    children, classes, onClose, ...other
  } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Grid
        container
        className={classes.modalContainer}
        alignContent="center"
        justifyContent="center"
      >
        <Grid item md={9} lg={8} className={classes.heading} />
        <Grid item md={3} lg={2} className={classes.close}>
          {onClose ? (
            <Button
              className={clsx(classes.closeLink, 'videoCloseBtn')}
              variant="text"
              onClick={onClose}
              disableRipple
              autoFocus
            >
              Close
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
});

function YouTubeVideo({
  imagePathUrl,
  imageAlt,
  imageDescription,
  videoHeader,
  videoHeading,
  videoEmbedUrl,
  videoTranscript,
  id,
  hide,
  theme,
  handleCallbackClick,
}) {
  const classes = makeStyles();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={`${theme === '' ? classes.videoWrapper : classes.videoThemeWrapper}`}
      id={id}
      hidden={hide ? 'hidden' : null}
    >
      <VideoImage
        videoHeader={videoHeader}
        videoHeading={videoHeading}
        imagePathUrl={imagePathUrl}
        imageAlt={imageAlt}
        imageDescription={imageDescription}
        setOpen={setOpen}
        id={id}
        theme={theme}
        handleCallbackClick={handleCallbackClick}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="embed-video-dialog-title"
        open={open}
        className={classes.dialog}
      >
        <DialogTitle id="embed-video-dialog-title" onClose={handleClose} />
        <MuiDialogContent>
          <Container className={classes.videoContainer}>
            <div className={classes.video}>
              <iframe
                id="iframeEmbedVideo"
                type="text/html"
                className={classes.iframeVideo}
                src={`${videoEmbedUrl}?autoplay=1&showinfo=0&modestbranding=1&cc_load_policy=1&rel=0&origin=https://phoenix.edu&controls=2`}
                title="YouTube video player"
                frameBorder="0"
                allowFullScreen
              />
            </div>
            <div className={classes.text}>
              <Typography variant="h1" className={classes.title}>{imageDescription}</Typography>
              {videoTranscript && (
                <Grid
                  container
                  justifyContent="center"
                  className={classes.transcriptWrapper}
                >
                  <Grid item xs={12} md={8}>
                    <Typography variant="h2" className={classes.transcriptTitle}>Transcript:</Typography>
                    <Typography
                      component="ol"
                      className={classes.transcriptList}
                      tabIndex="0"
                      id="transcriptContent"
                    >
                      {videoTranscript.map((frame, index) => (
                        <li key={index.toString()}>
                          <span className={classes.time}>{frame.time}</span>
                          {' '}
                          <span className={classes.timeText}>{frame.text}</span>
                        </li>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </div>
          </Container>
        </MuiDialogContent>
      </Dialog>
    </div>
  );
}

YouTubeVideo.defaultProps = {
  imageDescription: '',
  videoHeader: '',
  videoHeading: 'h2',
  videoTranscript: [],
  hide: false,
  theme: '',
  handleCallbackClick: () => {},
};

YouTubeVideo.propTypes = {
  imagePathUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  imageDescription: PropTypes.string,
  videoHeader: PropTypes.string,
  videoHeading: PropTypes.string,
  videoEmbedUrl: PropTypes.string.isRequired,
  videoTranscript: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string.isRequired,
  hide: PropTypes.bool,
  theme: PropTypes.string,
  handleCallbackClick: PropTypes.func,
};

export default YouTubeVideo;
