import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& .smallCtaBtn, & .logoutPhoneIcon': {
      color: '#30718D!important',
      borderBottomColor: '#30718D',
    },
    '& .MuiButton-label': {
      borderBottomColor: '#30718D!important',
    },
    '& .nameHelp': {
      margin: '2rem 1rem 0.8rem 0',
    },
  },
  mainGrid: {
    flexDirection: 'column',
    [theme.breakpoints.up('960')]: {
      flexDirection: 'row',
    },
  },
  containerGrid: {
    marginBottom: theme.spacing(0.8),
  },
  phoneContainerGrid: {
    margin: '2rem 2rem 0.8rem 0',
    paddingTop: 0,
  },
  emailItemGrid: {
    display: 'Grid',
    marginBottom: theme.spacing(0.4),
    '& a': {
      borderBottomColor: '#30718D!important',
    },
  },
  infoSection: {
    width: '100%',
  },
  heading: {
    '&.MuiTypography-h2, &.MuiTypography-h3, &.MuiTypography-h4': {
      fontSize: theme.spacing(2),
      fontWeight: 300,
      lineHeight: 1.4,
      margin: 0,
      paddingTop: theme.spacing(3.2),
      paddingBottom: theme.spacing(1.8),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(4.8),
        fontSize: theme.spacing(2.4),
      },
    },
  },
  h3: {
    '&.MuiTypography-h3': {
      fontSize: theme.spacing(1.8),
      fontWeight: 700,
      lineHeight: theme.spacing(3.2),
    },
  },
  erIcon: {
    height: theme.spacing(2.5),
    marginLeft: theme.spacing(-0.5),
  },
  fullname: {
    fontSize: theme.spacing(1.6),
  },
  phoneIcon: {
    width: 'auto',
    height: theme.spacing(2.5),
    marginRight: theme.spacing(0.6),
    fill: '#30718D!important',
  },
  phoneNumber: {
    color: '#30718D!important',
    '& .MuiButton-label': {
      borderBottomColor: '#30718D!important',
    },
  },
  mailIcon: {
    marginRight: theme.spacing(0.8),
    color: '#30718D!important',
  },
  emailER: {
    textDecoration: 'none',
    display: 'inline',
    lineHeight: 1.6,
    fontSize: theme.spacing(1.6),
    color: '#30718D!important',
    borderBottomColor: '#30718D!important',
  },
  helpSupportIcon: {
    textAlign: 'center',
    [theme.breakpoints.up('960')]: {
      alignSelf: 'flex-end',
    },
    '& svg': {
      width: 'auto',
      height: 'auto',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(40),
      },
    },
  },
  paper: {
    height: '100%',
    padding: theme.spacing(1.6),
    borderColor: theme.palette.line.greyL70,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2.4),
    },
  },
}));
