import React from 'react';
import { Grid, Button } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { UOPX_PHONE_NUMBER, phoneNumber10Digit } from '../../../modules/admissions/constants';
import styles from './styles';

export default function PhoneHelp({
  id,
  phoneNumber,
  onClickCallback,
  ariaLabel,
  styleOverride,
}) {
  const classes = styles();

  return (
    <Grid
      container
      className={clsx(classes.phoneContainerGrid, 'phoneHelp')}
      style={styleOverride}
    >
      <Grid item>
        <PhoneIcon
          className={clsx(classes.phoneIcon, 'logoutPhoneIcon')}
          aria-hidden="false"
          role="img"
          aria-label={ariaLabel}
        />
      </Grid>
      <Grid item>
        <Button
          id={id}
          className={clsx(classes.phoneNumber, 'smallCtaBtn')}
          variant="text"
          href={`tel:+1${phoneNumber10Digit(phoneNumber)}`}
          disableRipple
          onClick={onClickCallback}
        >
          {phoneNumber}
        </Button>
      </Grid>
    </Grid>
  );
}

PhoneHelp.defaultProps = {
  id: '',
  phoneNumber: UOPX_PHONE_NUMBER,
  onClickCallback: () => {},
  ariaLabel: 'Call the University of Phoenix at',
  styleOverride: {},
};

PhoneHelp.propTypes = {
  id: PropTypes.string,
  phoneNumber: PropTypes.string,
  onClickCallback: PropTypes.func,
  ariaLabel: PropTypes.string,
  styleOverride: PropTypes.objectOf(
    PropTypes.object,
  ),
};
