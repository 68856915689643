import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  imgContainer: {
    backgroundColor: theme.palette.background.lightBlue,
    padding: theme.spacing(2),
    '& .MuiTypography-h2, & .MuiTypography-h3': {
      marginTop: theme.spacing(-1),
    },
  },
  imgThemeContainer: {
    // ...
  },
  imgGrid: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
  },
  gridImg: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginRight: 0,
      width: '100%',
    },
  },
  imgPlay: {
    position: 'relative',
    display: 'inline-block',
    textAlign: 'center',
    border: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    outline: 0,
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  icon: {
    width: theme.spacing(4),
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(-2),
    position: 'absolute',
    top: '50%',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
      marginLeft: theme.spacing(-4),
      marginTop: theme.spacing(-4),
    },
  },
  iconWithTheme: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: theme.spacing(8),
    marginTop: theme.spacing(-4),
    marginLeft: theme.spacing(-4),
  },
  image: {
    display: 'block',
    height: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      maxWidth: '100%',
    },
  },
  gridText: {
    flex: '1 1 auto',
  },
  desc: {
    fontWeight: 300,
  },
}));
