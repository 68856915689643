/* eslint-disable linebreak-style */
import retryCallIfNeeded from '../../../restCalls';

const baseEndpointUrlV2 = '/api/leads/v2';

export default function LeadsService(client) {
  const config = {
    headers: {
      'x-custom-headers': 'leads-profile',
    },
  };
  const getSavedProgramsByPersonId = async personId => retryCallIfNeeded(client, () => client
    .get(`${baseEndpointUrlV2}/profile/programs/${personId}`, config));
  return {
    getSavedProgramsByPersonId,
  };
}
