import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import styles from './styles';
import { ReactComponent as ImagePlayIcon } from './imagePlayIcon.svg';
import previewImg from './preview/thumbnail.jpg';

const adobeDAMbaseURL = (window.__ENV || {}).REACT_APP_ADOBE_DAM_BASE_URL;

function VideoImage({
  videoHeader,
  videoHeading,
  imagePathUrl,
  imageAlt,
  imageDescription,
  setOpen,
  id,
  theme,
  handleCallbackClick,
}) {
  const classes = styles();
  const imageUrl = `${adobeDAMbaseURL}${imagePathUrl}`;

  const handleClickOpen = () => {
    setOpen(true);
    handleCallbackClick();
  };

  return (
    <div className={`${theme === '' ? classes.imgContainer : classes.imgThemeContainer}`}>
      {videoHeader && (
      <Typography variant={videoHeading}>
        {videoHeader}
      </Typography>
      )}
      {theme === '' ? (
        <Grid
          container
          alignItems="flex-start"
          className={classes.imgGrid}
        >
          <Grid item className={classes.gridImg}>
            <button
              type="button"
              className={classes.imgPlay}
              onClick={handleClickOpen}
              tabIndex="-1"
              id={`${id}Play`}
            >
              <a href={imageUrl} className="progressive replace" tabIndex="-1">
                <img src={previewImg} className="preview" alt={imageAlt} />
              </a>
              <ImagePlayIcon
                className={clsx(classes.icon, 'videoPlayBtn')}
                tabIndex="0"
                aria-label={`Play ${imageDescription} video`}
              />
            </button>
          </Grid>
          <Grid item className={classes.gridText}>
            {imageDescription && (
            <Typography className={classes.desc}>
              Watch:
              {' '}
              {imageDescription}
            </Typography>
            )}
          </Grid>
        </Grid>
      ) : null}
      {theme === 'black' ? (
        <button
          type="button"
          className={classes.imgPlay}
          onClick={handleClickOpen}
          tabIndex="-1"
          id={`${id}Play`}
        >
          <ImagePlayIcon
            className={clsx(classes.iconWithTheme, 'videoPlayThemeBtn')}
            tabIndex="0"
            aria-label={`Play ${imageDescription} video`}
          />
          <img src={imageUrl} className="blackTheme" alt={imageAlt} />
        </button>
      ) : null}
    </div>
  );
}

VideoImage.defaultProps = {
  videoHeader: '',
  videoHeading: 'h2',
  imageDescription: '',
  handleCallbackClick: () => {},
};

VideoImage.propTypes = {
  videoHeader: PropTypes.string,
  videoHeading: PropTypes.string,
  imagePathUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  imageDescription: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  handleCallbackClick: PropTypes.func,
};

export default VideoImage;
