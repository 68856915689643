import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  dialogActions: {
    flexDirection: 'column-reverse',
    '& .confirmBtn.MuiButton-textPrimary': {
      margin: '0.5rem 2.4rem 2rem!important',
    },
    '& .confirmBtn.MuiButton-textSecondary': {
      margin: '2.4rem!important',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& .confirmBtn.MuiButton-textPrimary': {
        margin: '2.4rem 0 2.4rem 2.4rem!important',
        minWidth: theme.spacing(16),
      },
      '& .confirmBtn.MuiButton-textSecondary': {
        margin: '2.4rem 0!important',
        minWidth: theme.spacing(16),
      },
    },
  },
  text: {
    color: theme.palette.text.primary,
  },
  list: {
    marginTop: '1rem!important',
  },
  erMessage: {
    color: 'black',
    fontSize: '1.6rem',
    '& a': {
      color: 'black',
    },
  },
}));
